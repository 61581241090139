@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body{
    color: rgba(255, 255, 255,80%);
    background: linear-gradient(to bottom, #000102, #0f3b82);
    
    /* font-family: "Fira Sans", sans-serif; */
    
  
  }

  .active {
    border-left: 4px solid #042153; 
    padding-left: 12px; /* Left padding to accommodate the border */
    color: #7d8899;
  }
  
 
  
